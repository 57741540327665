/* MyPage.css */
  
  .profile, .reviews {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .profile h2, .reviews h2 {
    color: #333;
  }
  
  .reviews ul {
    list-style-type: none;
    padding: 0;
  }
  
  .reviews li {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    margin-top: 0;
  }
  
  p {
    margin: 5px 0;
  }
  