nav {
    background-color: #333;
    color: white;
    padding: 10px;
/*    text-align: center; */
  /* ナビゲーションバーを画面の上部に固定 */
    position: fixed;  /* 位置を固定 */
    top: 0;           /* 画面の上端に */
    left: 0;          /* 画面の左端に */
    width: 100%;      /* 幅を画面全体に拡張 */
    z-index: 1000;    /* 他の要素より上に表示 */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);  /* 下部に影を追加 */
}
  
  .logo {
    float: left;
    font-size: 20px;
    margin-left: 20px;
  }
/* Navbar.css */

.nav-links {
  display: flex;
  list-style: none;
  gap: 20px;
  margin-right: 30px;
}
.hamburger-menu {
  display: none;  /* デフォルトでは非表示 */
  cursor: pointer;
  color: white;
  position: absolute; /* 絶対位置指定 */
  top: 15px; /* 上からの位置 */
  right: 50px; /* 右からの位置 */
  z-index: 1001; /* メニューと他の要素よりも前に表示 */
}

@media (max-width: 768px) {
  .nav-links {
    display: none;  /* スマホ表示時にメニューを非表示 */
  }

  .hamburger-menu {
    display: block; /* ハンバーガーメニューを表示 */
  }
}
  
  .nav-links li {
    display: inline;
    margin-left: 20px;
  }
  
  .nav-links li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .nav-links li a:hover {
    text-decoration: underline;
  }
  
  
  .show {
  display: flex;
  flex-direction: column; /* スマホ表示時のメニューの方向 */
  position: absolute; /* ナビゲーションバーの外にメニューを表示 */
  background-color: #333; /* 背景色 */
  width: 150px; /* 全幅 */
  top: 30px; /* ロゴの下 */
  right: 0; /* 右端から0pxの位置に配置 */
  z-index: 1000; /* 上に表示 */
  padding: 20px;
}

.xxshow {
  display: flex;
  flex-direction: column; /* メニューを縦方向に表示 */
  align-items: center; /* メニュー項目を中央寄せにする */
  position: absolute; /* 絶対位置指定でナビゲーションバーから独立 */
  top: 60px; /* ナビゲーションバーの下から配置 */
  right: 0; /* 右端から0pxの位置に配置 */
  width: 200px; /* メニューの幅を200pxに設定 */
  background-color: white; /* メニューの背景色を白に設定 */
  z-index: 1000; /* 他の要素より前面に表示 */
  text-align: none;
}

a {
  color: #fff;  /* リンクの文字色を白に設定 */
  text-decoration: none;  /* リンクの下線を除去 */
}
