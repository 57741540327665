/* ReviewDetail.css */
.reviewDetail {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .reviewDetail h1 {
    color: #333;
  }
  
  .reviewDetail p {
    color: #666;
    margin: 10px 0;
  }
  