/* CategoryList.css */
.categoryList {
    border-radius: 8px;
  }
  
  .categoryList h2 {
    color: #333;
    text-align: center; /* タイトルのテキストを中央揃えに */
  }
  
  .categoryList ul {
    list-style-type: none;
    padding: 0;
  }
  
  .categoryList li {
    margin-bottom: 15px;
    padding: 10px;
    background-color:#f0f0f0; /* 要素の背景色を白に */
    border-radius: 8px; /* 角を丸く */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 影を追加 */
  }
  
  .categoryList li a {
    color: #000000;
    text-decoration: none;
    font-weight: bold; /* リンクのフォントを太字に */
  }
  
  .categoryList li a:hover {
    text-decoration: underline; /* ホバー時に下線を表示 */
  }
  