/* App.css */
.App {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
html, body {
  height: 100%;
  overflow: auto; 
}

#root {
  min-height: 100vh; 
  /* ナビゲーションバーの下にあるコンテンツが隠れないようにスペースを確保 */
  padding-top:50px;  /* ナビゲーションバーの高さに応じて調整 */
  padding-bottom:50px;  /* ナビゲーションバーの高さに応じて調整 */

}
.main {
  margin: 20px;
  padding: 20px;
}

h1 {
  color: #333;
  text-align: center;
}
