/* ContactPage.css */
.contactPage {
    text-align: center;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 8px;
  }
  
  .formGroup input,
  .formGroup textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box; /* ボックスサイズを border-box に設定して、パディングと境界線の幅が要素の幅に含まれるようにします */
  }
  
  button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  