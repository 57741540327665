/* Footer.css */
.siteFooter {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px 0;
/*    position: absolute; */
    bottom: 0;
    width: 100%;
  }
  
  .siteFooter p {
    margin: 10px 0;
  }
  