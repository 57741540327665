/* CategoryPage.css */
  
  .categoryPage h1 {
    color: #333;
    text-align: center;
  }
  
  .categoryPage ul {
    list-style-type: none;
    padding: 0;
  }
  
  .categoryPage li {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  
  .categoryPage h2 {
    color: #222;
  }
  
  .categoryPage p {
    color: #666;
    margin: 10px 0;
  }
  