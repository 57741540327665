/* HomePage.css */
  .homePage{

    text-align: center;
  }
  
  .featuredReviews, .latestNews {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  h1 {
    color: #333;
  }
  
  h2 {
    color: #555;
  }
