/* ReviewList.css */
.reviewList {
    text-align: left;
  }
  
  .reviewList h1 {
    color: #333;
    text-align: center;
  }
  
  .reviewList ul {
    list-style-type: none;
    padding: 0;
  }
  
  .reviewList li {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  
  .reviewList h2 {
    color: #222;
  }
  
  .reviewList p {
    color: #666;
  }
  