/* EventList.css */
.eventList {
  text-align: left;
}

.eventList h1 {
  color: #333;
  text-align: center;
}

.eventList ul {
  list-style-type: none;
  padding: 0;
}

.eventList li {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.eventList h2 {
  color: #222;
}

.eventList p {
  color: #666;
}

/* スタイルの追加：公演追加ボタン */
.addEventButton {
  display: block;
  width: 200px;  /* 適当な幅 */
  margin: 20px auto;  /* 中央揃え */
  padding: 10px;
  background-color: #333;  /* アクセントカラー */
  color: #fff;  /* ボタンの文字色 */
  text-align: center;
  font-size: 16px;
  border: none;
  border-radius: 8px;  /* 他の要素と同様の角丸 */
  cursor: pointer;
  text-decoration: none;  /* リンクの下線を除去 */
  outline: none;
}

.addEventButton:hover {
  background-color: #333;  /* ホバー時の背景色を変えずに維持 */
}

.addEventButton button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.addEventButton button:hover {
  text-decoration: underline;  /* ホバー時に下線を表示 */
  text-decoration-color: #fff;  /* 下線の色を白に設定 */
  background-color: #333;  /* ホバー時の背景色を変えずに維持 */
}
