/* AddEvent.css */
.addEventForm {
    text-align: left;
    max-width: 600px;  /* フォームの最大幅を設定 */
    margin: 20px auto;  /* 中央揃え */
    padding: 20px;
    background-color: #fff;  /* フォームの背景色 */
    border-radius: 8px;  /* 角丸設定 */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);  /* 影を付けることで立体感を演出 */
  }
  
  .addEventForm h1 {
    color: #333;
    text-align: center;
  }
  
  .addEventForm label {
    display: block;  /* ラベルをブロック要素に */
    margin-bottom: 10px;  /* 下マージンで隙間を作る */
    color: #666;  /* ラベルの文字色 */
    font-size: 14px;  /* フォントサイズ */
  }
  
  .addEventForm input,
  .addEventForm textarea {
    width: 100%;  /* 幅を100%に設定して、親要素いっぱいに広がるように */
    padding: 8px;  /* パディングで入力しやすいように調整 */
    border-radius: 4px;  /* 入力フィールドも角丸に */
    border: 1px solid #ccc;  /* 境界線を明確に */
    box-sizing: border-box;  /* box-sizingをborder-boxにして、パディングを幅に含める */
  }
  
  .addEventForm button {
    display: block;  /* ボタンもブロック要素に */
    width: 100%;  /* 幅を100%に */
    padding: 10px;  /* パディングでタッチしやすいように */
    margin-top: 20px;  /* 上マージンでフォーム要素から隔てる */
    background-color: #333;  /* ボタンの背景色 */
    color: #fff;  /* ボタンの文字色 */
    border: none;
    border-radius: 8px;  /* 角丸 */
    cursor: pointer;
    font-size: 16px;  /* フォントサイズ */
    text-align: center;
  }
  
  .addEventForm button:hover {
    background-color: #333;  /* ホバー時の背景色 */
  }
  